<template>
    <div class="ktevent_container">
        <!-- section1 -->
        <section class="kt_event1">
            <ktevent-section1 />
        </section>
        <!-- section2 -->
        <section class="kt_event2">
            <ktevent-section2 />
        </section>
        <!-- section3 -->
        <section class="kt_event3">
            <ktevent-section3 />
        </section>
        <!-- section4 -->
        <section class="kt_event4">
            <ktevent-section4 />
        </section>
        <!-- section5 -->
        <section class="kt_event5">
            <ktevent-section5 />
        </section>
    </div>
</template>
<script>
export default {
  name: 'KtEvent',
  components: {
    'ktevent-section1': () => import(`@/components/collaboration/KtEventSection1.vue`),
    'ktevent-section2': () => import(`@/components/collaboration/KtEventSection2.vue`),
    'ktevent-section3': () => import(`@/components/collaboration/KtEventSection3.vue`),
    'ktevent-section4': () => import(`@/components/collaboration/KtEventSection4.vue`),
    'ktevent-section5': () => import(`@/components/collaboration/KtEventSection5.vue`)
  },
  data () {
    return {};
  },

  mounted () {},

  methods: {}
};
</script>
<style src="@/assets/css/collaboration/collaborfont.css"></style>
<style scoped src="@/assets/css/collaboration/ktevent.css"></style>
