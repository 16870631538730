import { render, staticRenderFns } from "./KtEvent.vue?vue&type=template&id=7b95c829&scoped=true"
import script from "./KtEvent.vue?vue&type=script&lang=js"
export * from "./KtEvent.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/collaboration/collaborfont.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@/assets/css/collaboration/ktevent.css?vue&type=style&index=1&id=7b95c829&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b95c829",
  null
  
)

export default component.exports